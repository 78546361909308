<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import ExportExcel  from "@/components/exportExcel";
import userService from "@/helpers/userService";
import errorHandler from '@/helpers/errorHandler'

/**
 * Starter component
 */
export default {
  page: {
    title: "Lectures list",
  },
  head() {
    return {
      title: `Lectures`,
    };
  },
  components: {
    Layout,
    PageHeader,
    ConfirmActionDialog,
    ExportExcel,
  },
  async created() {
    if(userService.methods.userRole() != 1){
      await this.$store.dispatch("chapter/getAllChapters").then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
      await this.$store.dispatch("option/getYearOption").then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
    }
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    chapters() {
      return this.$store.getters["chapter/allChapters"];
    },
    stages() {
      return this.$store.getters["option/years"];
    },
    rows() {
      return this.chapters.length;
    }
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("chapter/deleteChapter",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
      this.$bvModal.show('deleteRecord')
      this.recordId = id
    },
    GiveChanceRecord:function(){
     this.$store.dispatch("chapter/chanceChapter",{id:this.recordId,chance:1}).then((token)=>{
      errorHandler.methods.successful('Give chance successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('giveChanceRecord')
    },
     showGiveChanceModal(id) {
      this.$bvModal.show('giveChanceRecord')
      this.recordId = id
    },
    CloseChanceRecord:function(){
    this.$store.dispatch("chapter/chanceChapter",{id:this.recordId,chance:0}).then((token)=>{
      errorHandler.methods.successful('Close chance successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('closeChanceRecord')
    },
     showCloseChanceModal(id) {
      this.$bvModal.show('closeChanceRecord')
      this.recordId = id
    },
    viewProfile(record,index){
      this.$router.push('/chapters/' + record.id + '/details')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterByStage(){
      this.$store.dispatch("chapter/getAllChaptersByYear",this.stageFilter).then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
    }
  },
  data: () => ({    
    stageFilter: null,
    totalRows:0,
    recordId: null,
    title: "Lectures List",
    items: [
      {
        text: "Work Center",
        href: "/"
      },
      {
        text: "Lectures",
        href: "/chapters"
      }
    ],
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "number",
    sortDesc: false,
    filterOn: ["number","name","description"],
    fields: [
      {
        key: "name",
        label: "Name",
        sortable: true,
        selected: true
      },
      {
        key: "price",
        label: "Price",
        sortable: true,
        selected: true
      },
      {
        key: "educational_year",
        label: "Stage",
        sortable: true,
        selected: true
      },
      {
        key: "description",
        label: "Note",
        sortable: true,
        selected: true
      },
      {
        key: "give_chance",
        label: "Give Chance",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
  })
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                  <label class="d-inline-flex align-items-center ml-5">
                    Stage&nbsp;
                    <b-form-select
                      v-model="stageFilter"
                      size="sm"
                      :options="stages"
                      @change="filterByStage"
                    ></b-form-select
                    >
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <router-link to="/chapters/create" v-if="$store.getters['user/role'] == 0">
                    <b-button variant="success" class="btn-sm mr-2">
                      <i class="mdi mdi-plus"></i>
                      Add lecture
                    </b-button>
                  </router-link>
                  <export-excel :jsonData="chapters" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="chapters"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="viewProfile"
            >
            <template v-slot:cell(educational_year)="data">
              {{ data.item.educational_year.number + '-' + data.item.educational_year.description }}
            </template>
            <template v-slot:cell(give_chance)="data">
              <b-badge v-if="data.item.give_chance" variant="success">Yes</b-badge>
              <b-badge v-else variant="danger">No</b-badge>
            </template>
          <template v-slot:cell(action)="data" v-if="$store.getters['user/role'] == 0">
                <b-dropdown
                  class="btn-icon-onl"
                  right
                  toggle-class="arrow-none btn-light btn-sm"
                >
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal iconGray"></i>
                  </template>

                  <b-dropdown-item
                    :to="'/chapters/' + data.item.id + '/edit'"
                  >
                    <i
                      class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                 v-on:click="showDeleteModal(data.item.id)"
                  ><i
                    class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Delete
                  </b-dropdown-item>
                  <b-dropdown-item
                 v-on:click="showGiveChanceModal(data.item.id)"
                 v-if="!data.item.give_chance"
                  ><i
                    class="mdi mdi-check mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Give chance
                  </b-dropdown-item>
                  <b-dropdown-item
                 v-on:click="showCloseChanceModal(data.item.id)"
                 v-else
                  ><i
                    class="mdi mdi-close mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Close chance
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="deleteRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
        </template>
    </b-modal>
    <b-modal id="giveChanceRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="GiveChanceRecord()">Give chance</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('giveChanceRecord')">Cancel</b-button>
        </template>
    </b-modal>
    <b-modal id="closeChanceRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="CloseChanceRecord()">Close Chance</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('closeChanceRecord')">Cancel</b-button>
        </template>
    </b-modal>
  </Layout>
</template>
